
	// vue
	import { defineComponent, inject, computed } from "vue";
	// components
	import vueImg from "@/components/vueImg.vue";
	// ts
	import loading from "@/typeScript/common/screenLoading";

	export default defineComponent({
		name: "About",

		components: {
			vueImg
		},

		setup() {
			// global imort
			const STORE: any = inject("$store");
			// eslint-disable-next-line @typescript-eslint/no-var-requires
			const PROFILEPIC = require("@/../public/img/profilePic.jpg");
			// const resume = require("@/assets/Hrishikesh Karale-Resume.pdf");
			const START = new Date(2017, 9, 10).getTime();
			const TODAY = Date.now();
			let DIFF = (TODAY - START) / 1000;
			DIFF /= 60 * 60 * 24;
			const EXPERIENCE = {
				professional: Math.abs(Math.round(DIFF / 365.25)),
				academic: 2.5
			};
			// mixin
			loading();

			const blogList = computed(() => STORE.getters["contentModule/getSkills"]);

			const lisencesAndCertificates = computed(
				() => STORE.getters["contentModule/getLisencesAndCertificates"]
			);

			const honorsAndAwards = computed(
				() => STORE.getters["contentModule/getHonorsAndAwards"]
			);

			const uxProcess = computed(
				() => STORE.getters["contentModule/getUxProcess"]
			);

			const skillset = computed(() => STORE.getters["contentModule/getSkills"]);

			return {
				// resume,
				PROFILEPIC,
				EXPERIENCE,
				blogList,
				lisencesAndCertificates,
				honorsAndAwards,
				uxProcess,
				skillset
			};
		}
	});
